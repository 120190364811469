"use client";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  Activity,
  CheckIcon,
  ChevronRight,
  Dot,
  List,
  LogOut,
  Plus,
  SlidersHorizontal,
} from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useState } from "react";

const ProfileMenu = ({ children, inApp = false }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className={`rounded-full ${inApp ? "w-[60px] h-[60px] md:w-[80px] md:h-[80px]" : "w-[60px] h-[60px]"} inline-flex items-center justify-center text-black bg-white outline-none`}
          aria-label="Customise options"
        >
          {children}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="profile-menu min-w-[200px] z-[100] bg-white rounded-md border border-black py-1 px-1 shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          align="end"
        >
          <DropdownMenu.Item className="hover:bg-[#F50078] rounded-md hover:text-white transition-all duration-200 ease-in-out group text-sm leading-none  flex items-center  py-4 px-4 relative  select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1">
            <a
              href="/app"
              className="text-current flex gap-4 items-center w-full justify-between"
            >
              New Session <Plus className="h-4 w-4 opacity-70" />
            </a>
          </DropdownMenu.Item>

          <DropdownMenu.Item className="hover:bg-[#F50078] rounded-md hover:text-white transition-all duration-200 ease-in-out group text-sm leading-none  flex items-center py-4 px-4 relative select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1">
            <Link
              href="/app/history"
              className="text-current flex gap-4 items-center w-full justify-between"
            >
              History <Activity className="h-4 w-4 opacity-70" />
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Item className="hover:bg-[#F50078] rounded-md hover:text-white transition-all duration-200 ease-in-out group text-sm leading-none  flex items-center py-4 px-4 relative select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1">
            <Link
              href="/app/profile"
              className="text-current flex gap-4 items-center w-full justify-between"
            >
              My Profile <SlidersHorizontal className="h-4 w-4  opacity-70" />
            </Link>
          </DropdownMenu.Item>

          <DropdownMenu.Separator className="h-[1px] bg-[#d1d1d1] mx-2 my-3" />

          <DropdownMenu.Item className="hover:bg-[#F50078] rounded-md hover:text-white transition-all duration-200 ease-in-out group text-sm leading-none  flex items-center py-4 px-4 relative select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1">
            <button
              onClick={async () => await signOut()}
              className="text-current flex gap-4 items-center w-full justify-between"
            >
              Sign Out <LogOut className="h-4 w-4 opacity-70" />
            </button>
          </DropdownMenu.Item>

          <DropdownMenu.Arrow className="fill-black" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default ProfileMenu;
